const employee = [
  {
    object: 'Bundling',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Labelling',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Assembling',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Returning',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Management',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Queues',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Picking',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Packing',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Assembling',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'CheckOut',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Settings',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Place',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Orderadmin\\Products\\Entity\\AbstractOrder',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Orderadmin\\Products\\Entity\\Product\\Offer',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Movement\\Acceptance',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Movement\\Acceptance\\Item',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Movement\\Consumption',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Movement\\Inventory',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Reports\\Entity\\Report',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Orderadmin\\Accounts\\Entity\\Invoice',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Supply',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Orderadmin\\DeliveryServices\\Entity\\Movements\\Acceptance',
    type: 'object',
    permission: ['R']
  }
]

const client = [
  {
    object: 'Settings',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\DeliveryServices\\Entity\\Sender',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Products\\Entity\\Shop',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Warehouse',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Items',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Accounts\\Entity\\LegalEntity',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Products\\Entity\\AbstractOrder',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Movement\\Consumption',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Movement\\Inventory',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Movement\\Acceptance',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Movement\\Acceptance\\Item',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Orderadmin\\Accounts\\Entity\\Account',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Reports\\Entity\\Report',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Products\\Entity\\Product\\Offer',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Orderadmin\\Products\\Entity\\Product\\ExpectedOffer',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Accounts\\Entity\\Invoice',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Integrations\\Entity\\Integration',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\DeliveryServices\\Entity\\Movements\\Acceptance',
    type: 'object',
    permission: ['R']
  }
]

const supervisior = [
  {
    object: 'Orderadmin\\Automation',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Packing',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Bundling',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Labelling',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Assembling',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Returning',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Management',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Queues',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Picking',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Assembling',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'CheckOut',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Settings',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Users\\Entity\\User',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\DeliveryServices\\Entity\\Sender',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Products\\Entity\\Shop',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Warehouse',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Place',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Integrations\\Entity\\Integration',
    type: 'object',
    permission: ['R']
  },
  // {
  //   object: 'Orderadmin\\Notifications\\Entity\\Channel',
  //   type: 'object',
  //   permission: ['R']
  // },
  // {
  //   object: 'Orderadmin\\Notifications\\Entity\\Event',
  //   type: 'object',
  //   permission: ['R']
  // },
  // {
  //   object: 'Orderadmin\\Notifications\\Entity\\Task',
  //   type: 'object',
  //   permission: ['R']
  // },
  // {
  //   object: 'Orderadmin\\Notifications\\Entity\\Template',
  //   type: 'object',
  //   permission: ['R']
  // },
  // {
  //   object: 'Orderadmin\\Notifications\\Entity\\Crm\\Interaction',
  //   type: 'object',
  //   permission: ['R']
  // },
  {
    object: 'Orderadmin\\Accounts\\Entity\\LegalEntity',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Accounts\\Entity\\Rate',
    type: 'object',
    permission: ['R']
  },
  // {
  //   object: 'Application\\Entity\\Documents\\Template',
  //   type: 'object',
  //   permission: ['R']
  // },
  {
    object: 'Orderadmin\\Products\\Entity\\AbstractOrder',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Products\\Entity\\Product\\Offer',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Movement\\Acceptance',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Movement\\Acceptance\\Item',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Movement\\Consumption',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Movement\\Inventory',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Accounts\\Entity\\Account',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Reports\\Entity\\Report',
    type: 'object',
    permission: ['R']
  },
  // {
  //   object: 'Orderadmin\\Products\\Entity\\Product\\ExpectedOffer',
  //   type: 'object',
  //   permission: ['R']
  // },
  {
    object: 'Orderadmin\\Accounts\\Entity\\Invoice',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Supply',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Items',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\DeliveryServices\\Entity\\Movements\\Acceptance',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\View\\OfferInventory',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'WarehouseList',
    type: 'object',
    permission: ['R']
  }
  // {
  //   object: 'Orderadmin\\Notifications',
  //   type: 'object',
  //   permission: ['R']
  // },
]

const admin = [
  {
    object: 'Orderadmin\\Automation',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Packing',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Bundling',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Labelling',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Assembling',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Returning',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Management',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Queues',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Picking',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Assembling',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'CheckOut',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Settings',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Users\\Entity\\User',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\DeliveryServices\\Entity\\Sender',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Products\\Entity\\Shop',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Warehouse',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Place',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Integrations\\Entity\\Integration',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Notifications\\Entity\\Channel',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Notifications\\Entity\\Event',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Notifications\\Entity\\Task',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Notifications\\Entity\\Template',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Notifications\\Entity\\Crm\\Interaction',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Accounts\\Entity\\LegalEntity',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Accounts\\Entity\\Rate',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Application\\Entity\\Documents\\Template',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Users\\Entity\\Permission',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Products\\Entity\\AbstractOrder',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Products\\Entity\\Product\\Offer',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Movement\\Acceptance',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Movement\\Acceptance\\Item',
    permission: ['R'],
    type: 'object'
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Movement\\Consumption',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Movement\\Inventory',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Accounts\\Entity\\Account',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Reports\\Entity\\Report',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Products\\Entity\\Product\\ExpectedOffer',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Accounts\\Entity\\Invoice',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\Supply',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Notifications',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Application\\Entity\\Help\\Section',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Items',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\DeliveryServices\\Entity\\Movements\\Acceptance',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'Orderadmin\\Storage\\Entity\\View\\OfferInventory',
    type: 'object',
    permission: ['R']
  },
  {
    object: 'WarehouseList',
    type: 'object',
    permission: ['R']
  }
]

export function getPermissions (roles) {

  if (roles.includes(3)) {
    return admin
  }

  if (roles.includes(29)) {
    return supervisior
  }

  let base = []

  if (roles.includes(7)) {
    base = [...base, ...employee]
  }

  if (roles.includes(8)) {
    base = [...base, ...client]
  }else if(roles.includes(2)) {
    base = [...base, ...client]
  }

  return base
}
